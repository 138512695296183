<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(onSubmit)">
      <v-row>
        <v-col cols="12" sm="6">
          <h1>My profile</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <h4>Contact details</h4>
        </v-col>
      </v-row>
      <v-row class="mb-0">
        <v-col cols="6" sm="3" class="py-0">
          <text-input
              rules="required"
              label="First name"
              name="First name"
              v-model="customer.first_name"
              vid="first_name"
          />
        </v-col>
        <v-col cols="6" sm="3" class="py-0">
          <text-input
              rules="required"
              label="Last name"
              name="Last name"
              v-model="customer.last_name"
              vid="last_name"
          />
        </v-col>
      </v-row>
      <v-row class="my-0">
        <v-col cols="12" sm="6" class="py-0">
          <phone-number-input-component
            rules="required"
            label="Mobile phone"
            name="Mobile phone"
            v-model="customerPhone"
            @errorValue="getMobileErrorValue"
            vid="phone_mobile"
            outlined
            :initialValue="customer.phone_mobile"
          />
        </v-col>
      </v-row>
      <v-row class="my-0">
        <v-col cols="12" sm="6" class="py-0">
          <text-input
              rules="required|email"
              label="Email"
              name="Email"
              vid="email"
              v-model="customer.email"
              hint="Your portal username will also be updated to this new email address."
              @blur="searchByEmail"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <h4>Emergency contact details</h4>
          <span class="text-caption">An alternate contact if we are unable to to reach you in an urgent situation.</span>
        </v-col>
      </v-row>
      <v-row class="mb-0">
          <v-col class="py-0" cols="12" sm="6">
            <text-input
                v-model="customer.name_emergency"
                rules=""
                label="Emergency contact name"
                name="Emergency contact name"
            ></text-input>
          </v-col>
          <v-col class="py-0" cols="12" sm="6">
            <text-input
                v-model="customer.email_emergency"
                rules="email"
                label="Emergency contact email"
                name="Emergency contact email"
            ></text-input>
          </v-col>
        <v-col class="py-0" cols="12" sm="6">
          <phone-number-input-component
              label="Emergency contact phone"
              name="Emergency contact phone"
              v-model="customerEmergencyPhone"
              @errorValue="getEmergenncyPhoneError"
              vid="phone_emergency"
              outlined
              :initialValue="customer.phone_emergency"
          />
        </v-col>
        <v-col class="py-0" cols="12" sm="6">
          <text-input
              v-model="customer.relationship_emergency"
              label="Emergency relationship"
              name="Emergency relationship"
              vid="relationship_emergency"
          ></text-input>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-btn color="success" block
                 type="submit"
                 :disabled="error || emergencyPhoneError || existingAccount"
                 :loading="loading"
          >
            Save
          </v-btn>
          <v-btn block
                 class="mt-3"
          @click="$router.push({name: 'Home'})"
          >
            Back
          </v-btn>
        </v-col>
      </v-row>
    </form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import TextInput from "@/components/TextInputComponent";
import CustomersRepository from "@/api/CustomersRepository";
import store from "@/store";
import PhoneNumberInputComponent from '../components/PhoneNumberInputComponent.vue';

export default {
  name: 'BookTrialDayDetails',
  title: 'Book a trial day | Your details',
  components: {
    ValidationObserver,
    TextInput,
    PhoneNumberInputComponent,
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch("customer/get")
    next();
  },
  async beforeRouteUpdate(to, from, next) {
    await store.dispatch("customer/get")
    next();
  },
  async mounted() {
    this.oldEmail = this.customer.email
    this.customerPhone = this.customer.phone_mobile
    this.customerEmergencyPhone = this.customer.phone_emergency
  },
  data: () => ({
    loading: false,
    oldEmail: "",
    existingAccount: false,
    error: false,
    emergencyPhoneError: false,
    customerPhone: '',
    customerEmergencyPhone: '',
  }),
  computed: {
    customer: {
      get () {
        return this.$store.state.customer.customer;
      },
      set (value) {
        this.$store.commit('customer/SET_CUSTOMER', value);
      }
    },
  },
  methods: {
    async onSubmit () {
      this.loading = true;
      this.customer.phone_mobile = this.customerPhone
      this.customer.phone_emergency = this.customerEmergencyPhone
      try {
        let response = await CustomersRepository.update(this.customer);
        if(response === 'success') {
          this.loading = false;
          await this.$router.push({name: 'Home'});
        }
        this.loading = false;
      } catch (error) {
        this.$refs.form.setErrors(error.response.data.errors);
        //Scroll to the error message
        this.$nextTick(() => {
            const el = this.$el.querySelector(".v-messages.error--text:first-of-type");
            this.$vuetify.goTo(el, {offset: 80});
        });
      }
      this.loading = false;
    },
    getMobileErrorValue(error) {
      this.error = error
    },
    async searchByEmail() {
      if (this.customer.email && this.oldEmail !== this.customer.email) {
        this.loading = true;
        let response = await CustomersRepository.searchByEmail({email: this.customer.email});
        this.loading = false;
        if (Object.keys(response).length > 0 && this.oldEmail !== response.email) {
          this.existingAccount = true;
          this.$refs.form.setErrors({
            email: 'An existing account exists for this email address. Email addresses must be unique. Contact Kip to update your email address'
          });
        } else {
          this.existingAccount = false;
        }
      }
    },
    getEmergenncyPhoneError(error) {
      this.emergencyPhoneError = error
    }
  },
}
</script>